import React, { useState, useEffect } from 'react'
import { list, listExternal, utcConverter } from '../../actions/banners'
import { signedIn } from '../../actions/auth'

function getHiddenBanners () {
  try {
    return JSON.parse(localStorage.getItem('__banners')) || {}
  } catch {
    return []
  }
}

function saveHiddenBanners (list) {
  try {
    localStorage.setItem('__banners', JSON.stringify(list))
  } catch {}
}

function addHiddenBanner (id, timestamp) {
  const hidden = getHiddenBanners()
  hidden[id] = timestamp
  saveHiddenBanners(hidden)
}

function Banner ({ object }) {
  const [ visible, setVisible ] = useState(true)
  const handleClose = e => {
    setVisible(false)
    addHiddenBanner(object.id, object.updated_at)
  }
  if (!visible) {
    return null
  }
  return (
    <div className={`banner banner-${object.background}`}>
      <button type="button" className="close" onClick={handleClose}>
        <span>×</span>
      </button>
      <div dangerouslySetInnerHTML={{ __html: object.html }} />
    </div>
  )
}

export default function Banners () {
  const [ banners, setBanners ] = useState([])
  useEffect(() => {
    const listFn = signedIn() ? list : listExternal;
    listFn(1, 'active').then(response => {
      const hiddenIds = getHiddenBanners()
      let banners = response.data.objects.filter(o => !hiddenIds[o.id] || hiddenIds[o.id] !== o.updated_at)
      const now = new Date()
      banners = response.data.objects.filter(b => {
        if (b.start_time && b.end_time) {
          const [ startHour, startMinute ] = utcConverter(true, b.start_time).split(':').map(n => parseInt(n))
          const [ endHour, endMinute ] = utcConverter(true, b.end_time).split(':').map(n => parseInt(n))
          const start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), startHour, startMinute)
          const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), endHour, endMinute)
          const currentTime = now.getTime()
          if (end < start) {
            end.setDate(end.getDate() + 1)
          }
          return currentTime >= start.getTime() && currentTime < end.getTime()
        } else if (b.start_time || b.end_time) {
          return false
        }
        return true
      })
      setBanners(banners)
    })
  }, [])
  if (banners.length === 0) {
    return null
  }
  return (
    <div className="banners">
      {banners.map(b => <Banner key={b.id} object={b} />)}
    </div>
  )
}
