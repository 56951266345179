import config from '../config'
import HttpClient from '../helpers/httpclient'
import browserHistory from '../helpers/browserHistory'

const path = 'banners'

export function fetch (id) {
  return HttpClient.get(`${config.api}/${path}/${id}`)
}

export function list (page = 1, status = 'all', term = null, sort = null, filter = null) {
  const params = {
    params: { page, status, filter }
  }
  if (term) {
    params.params.q = term
  }
  if (sort) {
    params.params.order = sort.order
    params.params.direction = sort.direction
  }
  return HttpClient.get(`${config.api}/${path}`, params)
}

export function listExternal () {
  return HttpClient.get(`${config.api}/${path}/external`)
}

export function save (object, redirect = true) {
  const method = object.id ? 'put' : 'post'
  const url = `${config.api}/${path}/${object.id || ''}`
  return HttpClient[method](url, object).then(response => {
    if (redirect) {
      browserHistory.push(`/${path}`)
    }
    return response
  })
}

export function utcConverter(toLocalTime, time = null) {
  if (!time) {
    return null
  }
  const today = new Date()
  const offset = today.getTimezoneOffset() / 60 * (toLocalTime ? -1 : 1)
  let hours = parseInt(time.split(':')[0])
  hours = hours + offset
  if (hours >= 24) {
    hours = hours - 24
  }
  if (hours < 0) {
    hours = hours + 24
  }
  if (hours < 10) {
    hours = `0${hours}`
  }
  return `${hours}:${time.split(':')[1]}`
}